import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { color, ColorProps, compose, layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { colorsV2 } from '../../../colors-v2';

export interface SeparatorProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>,
    SpaceProps,
    LayoutProps,
    Omit<ColorProps, 'color'> {
  variant: 'vertical' | 'horizontal' | 'three-dot-horizontal' | 'dot';
}

const Main = styled.div<SpaceProps>`
  &.dot {
    min-width: 2px;
    min-height: 2px;
    width: 2px;
    height: 2px;
    background-color: ${colorsV2.gray100};
    border-radius: 50%;
    ${color}
  }
  &.vertical {
    width: 1px;
    height: 100%;
    background-color: ${colorsV2.gray100};
    ${color}
  }
  &.horizontal {
    height: 1px;
    width: 100%;
    background-color: ${colorsV2.gray100};
    ${color}
  }
  &.three-dot-horizontal {
    padding: 16px 0;
    display: grid;
    place-items: center;
    .dot-container {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: max-content;
      place-items: center;
      gap: 24px;
    }
    .dot {
      min-width: 4px;
      min-height: 4px;
      background-color: ${colorsV2.gray100};
      ${color};
      border-radius: 50%;
    }
  }

  && {
    ${compose(space, layout)}
  }
`;

export const Separator = React.memo((props: SeparatorProps) => {
  const { className, variant, children, ...rest } = props;
  return (
    <Main className={cl('dol-separator', variant, className)} {...rest}>
      {variant === 'three-dot-horizontal' && (
        <div className="dot-container">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      )}
    </Main>
  );
});
